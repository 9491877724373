<template>
  <div class="medium-10 column signup">
    <div class="text-center medium-12 signup--hero">
      <img
        :src="globalConfig.logo"
        :alt="globalConfig.installationName"
        class="hero--logo"
      />
      <h2 v-if="isAppsumoUser" class="hero--title">
        Welcome Sumo-lings!
      </h2>
      <h2 v-else class="hero--title">
        {{ $t('REGISTER.TRY_WOOT') }}
      </h2>
    </div>
    <div v-if="!isApsumoChecking" class="row align-center">
      <div class="small-12 medium-6 large-5 column">
        <form class="signup--box login-box" @submit.prevent="submit">
          <woot-input
            v-model="credentials.fullName"
            :class="{ error: $v.credentials.fullName.$error }"
            :label="$t('REGISTER.FULL_NAME.LABEL')"
            :placeholder="$t('REGISTER.FULL_NAME.PLACEHOLDER')"
            :error="
              $v.credentials.fullName.$error
                ? $t('REGISTER.FULL_NAME.ERROR')
                : ''
            "
            @blur="$v.credentials.fullName.$touch"
          />
          <woot-input
            v-model.trim="credentials.email"
            type="email"
            :class="{ error: $v.credentials.email.$error }"
            :label="$t('REGISTER.EMAIL.LABEL')"
            :placeholder="$t('REGISTER.EMAIL.PLACEHOLDER')"
            :readonly="readOnlyFields.email"
            :error="
              $v.credentials.email.$error ? $t('REGISTER.EMAIL.ERROR') : ''
            "
            @blur="$v.credentials.email.$touch"
          />

          <div v-if="!hideFields.phone_number">
            <div>
              <label>{{$t('REGISTER.COUNTRY_CODE.LABEL')}}</label>
              <select
                @input="onCountryCodeChange"
                v-model="selected_country_code"
                :disabled="readOnlyFields.phone_number || readOnlyFields.selected_country_code"
              >
                <option
                  v-for="code in phone_code_list"
                  :key="code.name"
                  :value="code.dial_code"
                >
                  {{code.emoji}} {{ code.dial_code }}
                </option>
              </select>
            </div>
            <div>
              <woot-input
                v-if="readOnlyFields.phone_number"
                v-model="input_phone_number"
                :class="{ error: $v.credentials.phone_number.$error }"
                :label="$t('REGISTER.PHONE_NUMBER.LABEL')"
                :placeholder="$t('REGISTER.PHONE_NUMBER.PLACEHOLDER')"
                :error="
                  $v.credentials.accountName.$error
                    ? $t('REGISTER.PHONE_NUMBER.ERROR')
                    : ''
                "
                :readonly="readOnlyFields.phone_number"
                @input="onPhoneNumberChange"
                @blur="$v.credentials.phone_number.$touch"
              />
              <woot-input
                v-else
                :class="{ error: $v.credentials.phone_number.$error }"
                :label="$t('REGISTER.PHONE_NUMBER.LABEL')"
                :placeholder="$t('REGISTER.PHONE_NUMBER.PLACEHOLDER')"
                :error="
                  $v.credentials.accountName.$error
                    ? $t('REGISTER.PHONE_NUMBER.ERROR')
                    : ''
                "
                @input="onPhoneNumberChange"
                @blur="$v.credentials.phone_number.$touch"
              />
            </div>
          </div>

          <woot-input
            v-model="credentials.accountName"
            :class="{ error: $v.credentials.accountName.$error }"
            :label="$t('REGISTER.ACCOUNT_NAME.LABEL')"
            :placeholder="$t('REGISTER.ACCOUNT_NAME.PLACEHOLDER')"
            :readonly="readOnlyFields.accountName"
            :error="
              $v.credentials.accountName.$error
                ? $t('REGISTER.ACCOUNT_NAME.ERROR')
                : ''
            "
            @blur="$v.credentials.accountName.$touch"
          />

          <div v-if="!hideFields.currency">
            <label>Default Currency</label>
            <select v-model="credentials.currency" :disabled="readOnlyFields.currency" :required="'required'">
              <option
                v-for="option in currencies"
                :key="option.key"
                :value="option.value"
              >
                {{ option.key }}
              </option>
            </select>
          </div>
          <woot-input
            v-model.trim="credentials.password"
            type="password"
            :class="{ error: $v.credentials.password.$error }"
            :label="$t('LOGIN.PASSWORD.LABEL')"
            :placeholder="$t('SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER')"
            :error="
              $v.credentials.password.$error
                ? $t('SET_NEW_PASSWORD.PASSWORD.ERROR')
                : ''
            "
            @blur="$v.credentials.password.$touch"
          />

          <woot-input
            v-model.trim="credentials.confirmPassword"
            type="password"
            :class="{ error: $v.credentials.confirmPassword.$error }"
            :label="$t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.LABEL')"
            :placeholder="$t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.PLACEHOLDER')"
            :error="
              $v.credentials.confirmPassword.$error
                ? $t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.ERROR')
                : ''
            "
            @blur="$v.credentials.confirmPassword.$touch"
          />
          <div v-if="globalConfig.hCaptchaSiteKey" class="h-captcha--box">
            <vue-hcaptcha
              :sitekey="globalConfig.hCaptchaSiteKey"
              @verify="onRecaptchaVerified"
            />
          </div>
          <woot-submit-button
            :disabled="isSignupInProgress || !hasAValidCaptcha"
            :button-text="$t('REGISTER.SUBMIT')"
            :loading="isSignupInProgress"
            button-class="large expanded"
          >
          </woot-submit-button>
          <p class="accept--terms" v-html="termsLink"></p>
        </form>
        <div class="column text-center sigin--footer">
          <span>{{ $t('REGISTER.HAVE_AN_ACCOUNT') }}</span>
          <router-link :to="externalPath">
            {{
              useInstallationName(
                $t('LOGIN.TITLE'),
                globalConfig.installationName
              )
            }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { DEFAULT_REDIRECT_URL } from '../../constants';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import {phone_code_list} from "./country_code";
import endPoints from '../../../dashboard/api/endPoints';
import {getUserAttributes,popupHelpers,attributeHelpers} from "dashboard/helper/demoAutomationHelper.js"
import ApiClient from "../../../dashboard/api/ApiClient"

export default {
  components: {
    VueHcaptcha,
  },
  mixins: [globalConfigMixin, alertMixin],
  data() {
    return {
      credentials: {
        accountName: '',
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
        hCaptchaClientResponse: '',
        currency: 'usd',
        phone_number: "",
      },
      currencies: [
        { key: 'USD', value: 'usd' },
        { key: 'MYR', value: 'myr' },
      ],
      isSignupInProgress: false,
      error: '',
      buy_now_plan_id: this.$route.query.buy_now_plan_id ? this.$route.query.buy_now_plan_id : '',
      fpr: this.$route.query.fpr ? this.$route.query.fpr : '',
      phone_code_list,
      selected_country_code:"+60",
      input_phone_number:"",
      source_phone_number: null,
      isAppsumoUser: false,
      isApsumoChecking:true,
      hideFields:{
        email: false,
        phone_number: false,
        currency: false,
      },
      readOnlyFields:{
        email: false,
        phone_number: false,
        selected_country_code:false,
        currency: false,
        accountName:false,
      },
    };
  },
  mounted() {
    console.log('mounted', this.$route.query.buy_now_plan_id);
    this.checkIsAppsumoUser();
  },

  validations: {
    credentials: {
      accountName: {
        required,
        minLength: minLength(2),
      },
      fullName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      phone_number: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        isEqPassword(value) {
          if (value !== this.credentials.password) {
            return false;
          }
          return true;
        },
      },
    },
  },

  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    externalPath() {
      let loginPath = { name: 'login', query: {} };

      if (this.buy_now_plan_id !== '') {
        loginPath.query.buy_now_plan_id = this.buy_now_plan_id;
      }
      if (this.fpr !== '') {
        loginPath.query.fpr = this.fpr;
      }

      return loginPath;
    },

    termsLink() {
      return this.$t('REGISTER.TERMS_ACCEPT')
        .replace(
          'https://peasy.ai/terms-of-service',
          this.globalConfig.termsURL
        )
        .replace(
          'https://peasy.ai/privacy-policy',
          this.globalConfig.privacyURL
        );
    },
    hasAValidCaptcha() {
      if (this.globalConfig.hCaptchaSiteKey) {
        return !!this.credentials.hCaptchaClientResponse;
      }
      return true;
    },

  },
  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async submit() {
      console.log("hideFields",this.hideFields,"readOnly",this.readOnlyFields,'credentials',this.credentials);
      const params = Object.fromEntries(new URLSearchParams(window.location.search))
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isSignupInProgress = true;
      try {

        if (this.isAppsumoUser) {
          // call the API with apsumo true
          // this.credentials.appsumo = true;
            this.credentials.phone_number = "";
        }
        console.log(this.credentials,"Body cred");

        const response = await Auth.register(this.credentials);
        if (response.status === 200) {
          console.log("register response.data?.data",response.data);
          // return;
          // if apsumo user, then redirect to billing screen
          if (this.isAppsumoUser) {
            const account_id = response.data?.data?.account_id || null;
            window.location =  `${DEFAULT_REDIRECT_URL}${endPoints('subscriptionPlans').url(account_id)}`;
            console.log("redirecting to billing screen");
            return ;
          }

          // destructure response here
          const account_id = response.data?.data?.account_id || null;
          const api_access_token = response.data?.data?.access_token || null;

          // setLabel at $peasy for new signup
          window.$peasy?.setUser(this.credentials.email,{
            name: this.credentials.fullName,
            email: this.credentials.email,
          })
          await this.delay(2000);
          window.$peasy?.setLabel("new_signup");

          // set onboard_class userAttribute for grant user
          if (params.source === 'grant') {
            // immediate sign up as grant, pass dummy plan_id to bypass update_onboard_class_value method check
            const grantUserObj = {
              email:this.credentials.email,
              name:this.credentials.fullName,
              plan_id: 20
            }
            await this.addUserAttributeOutsideAccountScope(grantUserObj);
          }
          // Pemangkin user with plan_id=28
          if (this.buy_now_plan_id?.toString() === '28') {
            await popupHelpers.saveAtPeasySdkContactAttributes(this.credentials.email,this.credentials.fullName,{
              pemangkin:'signup',
            });
          }
          await this.delay(2000);


          window.location = DEFAULT_REDIRECT_URL;

          if (this.buy_now_plan_id !== '') {
            const userData = response.data?.data || null;
            let externalUrl =
              DEFAULT_REDIRECT_URL +
              `accounts/${userData.account_id}/settings/subscription-plans?buy_now_plan_id=${this.buy_now_plan_id}`;
            if (this.fpr !== '') {
              externalUrl = externalUrl + '&fpr=' + this.fpr;
            }
            window.location = externalUrl;
          } else if (this.fpr !== '') {
            let externalUrl = DEFAULT_REDIRECT_URL;
            externalUrl = externalUrl + '?fpr=' + this.fpr;
            window.location = externalUrl;
          } else {
            window.location = DEFAULT_REDIRECT_URL;
          }
        }
      } catch (error) {
        console.log(error);
        let errorMessage = this.$t('REGISTER.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.isSignupInProgress = false;
      }
    },
    onRecaptchaVerified(token) {
      this.credentials.hCaptchaClientResponse = token;
    },

    onCountryCodeChange(event){
      this.selected_country_code = event.target.value;
      this.credentials.phone_number = `${event.target.value}${this.input_phone_number}`;
    },
    onPhoneNumberChange(value){
      this.input_phone_number = value;
      this.credentials.phone_number = `${this.selected_country_code}${value}`;
    },
    onAppsumoPhoneNumberChange(value,countryCode){
      this.input_phone_number = value;
      this.selected_country_code = countryCode;
      this.credentials.phone_number = `${countryCode}${value}`;
    },



    async checkIsAppsumoUser(){
      this.isApsumoChecking = true;
      const params = new URLSearchParams(window.location.search);
      const source = params.get('source');
      const activation_email = params.get('activation_email');
      const activation_phone = params.get('activation_phone');
      const activation_country_code = params.get('activation_country_code');
      const buy_now_plan_id = params.get('buy_now_plan_id');
      const uuid = params.get('uuid');

      let isSourceAppsumo = source === 'appsumo';
      const isSourceGrant = source === 'grant';
      if (isSourceAppsumo && activation_email) {
        try {
          const {data:chekRes} = await Auth.checkValidAppsumouser(activation_email,uuid);
          console.log(chekRes,"isApsumo valid user",chekRes.valid);
          if (chekRes?.valid) {
            this.credentials.uuid = uuid;
            this.credentials.appsumo_id = chekRes?.appsumo_id;
            if (activation_email) {
              // config email fields
              this.credentials.email = decodeURIComponent(activation_email);
              this.readOnlyFields.email = true;
              // hide phone field
              this.credentials.phone_number = '+601234567'; // dummy number to pass validation
              this.hideFields.phone_number = true;
              this.hideFields.currency = true;
            }else if (activation_phone && activation_country_code) {
              // config phone fields
              // const countryDialCode = phone_code_list.find(phoneEl=>phoneEl.code === activation_country_code)?.dial_code;
              // console.log(countryDialCode,activation_phone,activation_country_code);
              // this.onAppsumoPhoneNumberChange(activation_phone,countryDialCode);
              // this.readOnlyFields.phone_number = true;
            }
            // set phone number and country code for select option
            this.isAppsumoUser = true;
            console.log(this.credentials,"Credentials setting");
          }else{
            this.isAppsumoUser = false;
            params.delete('source');
            params.delete('activation_email');
            params.delete('uuid');
            const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
            window.location.href = newUrl;
          }
        } catch (error) {
          console.log(error);
          this.isAppsumoUser = false;
          params.delete('source');
          params.delete('activation_email');
          params.delete('uuid');
          const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
          window.location.href = newUrl;
        }
      }else if(isSourceGrant && buy_now_plan_id){
        this.configGrantRegisterForm();
      } else if (source && activation_phone && activation_country_code) {
        // do for plan B
        const countryDialCode = phone_code_list.find(phoneEl=>phoneEl.code === activation_country_code)?.dial_code;
        console.log(countryDialCode,activation_phone,activation_country_code);
        this.onAppsumoPhoneNumberChange(activation_phone,countryDialCode);
        this.credentials.source = source;   // set the source vaue with the credential as params
        this.readOnlyFields.phone_number = true;
        this.hideFields.currency = true;
      };
      this.isApsumoChecking = false;
    },

    async configGrantRegisterForm(){
      const params = new URLSearchParams(window.location.search);
      const {source,activation_email,activation_account_name,buy_now_plan_id} =  Object.fromEntries(params);
      console.log({source,activation_email,activation_account_name,buy_now_plan_id});
      if (source === 'grant' && buy_now_plan_id) {
        // call api to check if email is real
        try {
          // const {data:chekRes} = await Auth.checkValidGrantUser(activation_email);
          // console.log(chekRes,"isGrant valid user",chekRes.valid);
          const grantOption = {
            headers:{"Authorization":"Bearer xxxxxxxxxxxxxxxxxxxxxxxxxxxxx"},
          }
          // const {data:chekRes} = await axios.post(endPoints('grantCheck').url(activation_email),null,grantOption);
          // console.log({chekRes});
          const chekRes = {valid:true}
          if (chekRes.valid) {
            this.credentials.source = source;
            // email config
            this.credentials.email = decodeURIComponent(activation_email);
            this.readOnlyFields.email = true;
            // coutnry code config
            this.selected_country_code = '+60';
            this.readOnlyFields.selected_country_code = true;
            // currency config
            this.credentials.currency = 'myr';
            this.readOnlyFields.currency = true;
            // account name config
            this.credentials.accountName = activation_account_name;
            this.readOnlyFields.accountName = true;
          }else{
            // delete the source
            ['source','activation_email','activation_account_name',].forEach(param =>{
              params.delete(param);
            })
            const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
            window.location.href = newUrl;
          }
        } catch (error) {
          console.log(error);
        }

      }else{

      }
    },

    // calling this outside of accountscope to generate the url and access token
    async addUserAttributeOutsideAccountScope(user) {
      try {
        // triger event to update onboarding_class
        const {peasy_sale} = attributeHelpers.onboardClassValues;
        await attributeHelpers.update_onboard_class_value(user,peasy_sale.signUp);
      } catch (error) {
        console.error('Error adding user attribute:', error);
      }
    },


  },
};
</script>
<style scoped lang="scss">
.signup {
  .signup--hero {
    margin-bottom: var(--space-larger);

    .hero--logo {
      width: 180px;
    }

    .hero--title {
      margin-top: var(--space-large);
      font-weight: var(--font-weight-light);
    }
  }

  .signup--box {
    padding: var(--space-large);

    label {
      font-size: var(--font-size-default);
      color: var(--b-600);

      input {
        padding: var(--space-slab);
        height: var(--space-larger);
        font-size: var(--font-size-default);
      }
    }
  }

  .sigin--footer {
    padding: var(--space-medium);
    font-size: var(--font-size-default);

    > a {
      font-weight: var(--font-weight-bold);
    }
  }

  .accept--terms {
    font-size: var(--font-size-small);
    text-align: center;
    margin: var(--space-normal) 0 0 0;
  }

  .h-captcha--box {
    margin-bottom: var(--space-one);
  }
}
</style>
