
export const DAY_NAMES = [
    { id: 0, title: "Sunday" },
    { id: 1, title: "Monday" },
    { id: 2, title: "Tuesday" },
    { id: 3, title: "Wednesday" },
    { id: 4, title: "Thursday" },
    { id: 5, title: "Friday" },
    { id: 6, title: "Saturday" }
];

export const MESSAGE_ACTIONS_LIST = [
    {
        id:"order_created",
        title:"A new order is created",
        defaultMessage:"Hi {{contact.name}}, would you like to consider adding fries to your order for only extra RM 2?",
    },
    {
        id:"order_pending",
        title:"Order pending confirmation from customer",
        defaultMessage:"Hi {{contact.name}}, we have receive your order no: {{order.id}}. Please wait while we are preparing your order, thank you.",
    },
    {
        id:"order_cancelled",
        title:"Customer have cancelled the order",
        defaultMessage:"So sorry that you have to cancel your order, would you consider reordering again if we give you a 5% discount",
    },
    {
        id:"order_completed",
        title:"Order is completed",
        defaultMessage:"Thank you {{contact.name}} for buying from us. Let us know how do you like our product. Look forward to seeing you again.",
    },
    {
        id:"order_paid",
        title:"Customer have paid for the order",
        defaultMessage:"Thanks {{contact.name}}, we have received your payment of {{order.amount}}",
    },
    {
        id:"order_delivered",
        title:"You have deliver the order",
        defaultMessage:"Hi {{contact.name}}, order no: {{order.id}}, is on the way to you right now.",
    },
    {
        id:"order_packed",
        title:"Order is ready for collection",
        defaultMessage:"Hi {{contact.name}}, order no: {{order.id}}, is ready for collection. Please prepare and pay RM {{order.amount}} at the counter",
    },
    {
        id:"order_aditional_payment",
        title:"To request payment",
        defaultMessage:"Hi {{contact.name}}, pay this extra RM {{order.pending_partial_amount}}, here is the link {{order.extra_payment_link}}",
    },
    {
        id:"order_refund_success",
        title:"To notify customer there's a refund",
        defaultMessage:"Hi {{contact.name}}, Your order value is changed, we will refund you RM {{order.refund_amount}}",
    },
]
